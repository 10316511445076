<template>
 <div class="content">
   正在跳转中，请稍等。
 </div>
</template>

<script>
import { useRoute, } from "vue-router";
import { onMounted } from "vue";
import wechatApi from "@/api/wechatServer";

export default {
  name: "index",
  components: {


  },
  setup () {
    const route = useRoute();
    // const url = route.query;
    // console.log('url', url)

    let data = {
      appId:route.query.appId,
      batchId: route.query.batchId,
      openId: route.query.openId,
      tenant: route.query.tenant,
    }
    wechatApi.saveMongoDbContactOpenRecord('',data).then(()=>{
    })

    setTimeout(() => {
      window.open(route.query.jumpUrl, '_self');
    }, 1000);

    onMounted(() => {



    });

    return {
      route,



    }

  }


};
</script>

<style scoped lang="less">
.content {

}

</style>